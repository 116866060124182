import React from 'react';
import './RentersCarousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {withTranslation} from "react-i18next";
import RentersCarouselCard from "./RentersCarouselCard";
import ArrowDown from '../../resources/images/ic-arrow-down.svg';

class RentersCarousel extends React.Component {

    state = {
        mobileCardsToShow: 3,
        windowWidth: window.innerWidth
    }

    constructor(props) {
        super(props);
        this.addCards = this.addCards.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };

    addCards(){
        let newAmount = this.state.mobileCardsToShow + 3;
        this.setState({mobileCardsToShow: newAmount})
    }

    render() {
        const {windowWidth, mobileCardsToShow} = this.state;
        const {renters, tenantsTitle, tenantsDescription} = this.props;
        const trans = this.props.t;
        const carouselSettings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: (renters.length >= 3 ? 3 : renters.length),
            slidesToScroll: (renters.length >= 3 ? 3 : renters.length),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: (renters.length >= 2 ? 2 : renters.length),
                        slidesToScroll: (renters.length >= 2 ? 2 : renters.length),
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: "unslick"
                }
            ]
        };

        return (

          <div className="renters-carousel-container">

              <div className="renters-carousel-text">
                  <h2 className="renters-carousel-title">{tenantsTitle}</h2>
                  <p className="renters-carousel-description">{tenantsDescription}</p>
              </div>

              <Slider className="renters-carousel" {...carouselSettings}>

                  {renters.map((renter, index) => {

                      if(windowWidth <= 480){
                          return (index + 1 <= mobileCardsToShow)
                          ?
                          <RentersCarouselCard
                              key={renter.id}
                              profilePictureUrl={renter.profilePictureUrl}
                              name={renter.name}
                              position={renter.position}
                              review={renter.review}
                              facebook={renter.facebook}
                              twitter={renter.twitter}
                              linkedIn={renter.linkedIn}
                              instagram={renter.instagram}
                              website={renter.website}
                          />
                          :
                          null
                      }
                      return <RentersCarouselCard
                          key={renter.id}
                          profilePictureUrl={renter.profilePictureUrl}
                          name={renter.name}
                          position={renter.position}
                          review={renter.review}
                          facebook={renter.facebook}
                          twitter={renter.twitter}
                          linkedIn={renter.linkedIn}
                          instagram={renter.instagram}
                          website={renter.website}
                      />
                  })}

              </Slider>

              <div className={"renters-carousel-mobile-button" + (mobileCardsToShow >= renters.length ? " hide-mobile-button" : "")} onClick={this.addCards}>
                  <span className="renters-carousel-mobile-button-text">{trans("tenants.tenantsMobileButton")}</span>
                  <img className="renters-carousel-mobile-button-image" src={ArrowDown} alt=""/>
              </div>


          </div>
        );
    }
}

export default withTranslation()(RentersCarousel);