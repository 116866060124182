import React from "react";
import './AboutPage.css'
import Navigation from "../../components/navigation/Navigation";
import {getAboutRequest, getFooterRequest} from "../../networking/Requests";
import Footer from "../../components/footer/Footer";
import CardSlider from "../../components/slider/CardSlider";
import {SliderAlign} from "../../constants/SliderAlign";
import {getI18n, withTranslation} from "react-i18next";
import Card from "../../components/slider/Card";
import MediaCarousel from "../../components/media-carousel/MediaCarousel";
import RentersCarousel from "../../components/renters-carousel/RentersCarousel";
import Founders from "../../components/founders/Founders";
import Loading from "../../components/loading/Loading";
import Decoration1 from '../../resources/images/page-decoration-6.svg';
import Decoration2 from '../../resources/images/page-decoration-7.svg';
import Decoration3 from '../../resources/images/page-decoration-8.svg';
import MetaTags from "react-meta-tags";

class AboutPage extends React.Component {

    state = {
        footerData: null,
        aboutData: null
    }

    constructor(props){
        super(props);
        if(props.lang && props.lang !== getI18n().language){
            getI18n().changeLanguage(this.props.lang);
        }
    }

    async componentDidMount() {
        Promise.all([
            getFooterRequest(),
            getAboutRequest()
        ])
        .then(result => {
            const [footerData, aboutData] = result;
            this.setState({footerData, aboutData});
        })
    }

    createCards(){
        let cardArray = [];
        this.state.aboutData.detail.impressions.forEach( (impression) => {
            cardArray.push(
                <Card
                    key={impression.id}
                    id={impression.id}
                    title={impression.title}
                    description={impression.description}
                    imageUrl={impression.imageUrl}
                />
            )
        });
        return cardArray
    }

    render(){

        const {aboutData, footerData} = this.state;

        if(!footerData || !aboutData){
            return (
                <Loading />
            );
        }

        return(
            <div>

                <MetaTags>
                    <meta property="og:title" content="De Dorus - About"/>
                    <title>De Dorus - About</title>
                    <link rel="canonical" href="https://dedorus.nl/about" />
                </MetaTags>

                <Navigation
                    {...this.props}
                    socials={footerData.socials}
                />

                <main>
                    <section className="media-carousel-section">
                        <img id="about-page-decoration-1" src={Decoration1} alt="decoration"/>
                        <img id="about-page-decoration-2" src={Decoration2} alt="decoration"/>
                        <MediaCarousel
                            title={aboutData.detail.title}
                            description={aboutData.detail.description}
                            media={[
                                aboutData.detail.attachment1,
                                aboutData.detail.attachment2,
                                aboutData.detail.attachment3,
                                aboutData.detail.attachment4,
                                aboutData.detail.attachment5,
                            ]}/>
                    </section>

                    {aboutData.detail.renters.length !== 0
                        ?
                        <section className="renters-carousel-section">
                            <RentersCarousel
                                tenantsTitle={aboutData.detail.tenantsTitle}
                                tenantsDescription={aboutData.detail.tenantsDescription}
                                renters={aboutData.detail.renters}
                            />
                        </section>
                        :
                        null
                    }

                    <section className="founders-section">
                        <Founders
                            quote={aboutData.detail.quote}
                            image1={aboutData.detail.image1}
                            image2={aboutData.detail.image2}
                            image3={aboutData.detail.image3}
                        />
                    </section>

                    <section className="impressions-section">
                        <img id="about-page-decoration-3" src={Decoration3} alt="decoration"/>
                        <CardSlider
                            cards={this.createCards()}
                            title={aboutData.detail.subTitle}
                            description={aboutData.detail.subDescription}
                            orientation={SliderAlign.RIGHT}
                        />
                    </section>
                </main>

                <Footer data={footerData}/>
            </div>
        );
    }
}

export default withTranslation()(AboutPage);