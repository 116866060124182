import React from 'react';
import './Founders.css';
import {withTranslation} from "react-i18next";

class Founders extends React.Component {

    render() {

        const {quote, image1, image2, image3} = this.props;
        const trans = this.props.t;

        return (
            <div className="founders-container">
                <div className="founders-images-container">
                    <div className="founders-image-container">
                        <img id="founder-image-1" className="founders-image" src={image1} alt=""/>
                    </div>

                    <div className="founders-image-container">
                        <img id="founder-image-2" className="founders-image" src={image2} alt=""/>
                    </div>

                    <div className="founders-image-container">
                        <img id="founder-image-3" className="founders-image" src={image3} alt=""/>
                    </div>
                </div>
                <div className="founders-text-container">
                    <div className="founders-quote" dangerouslySetInnerHTML={{__html: quote}}/>
                    <span className="founders-names">{trans("founders.foundersNames")}</span>
                    <span className="founders-position">{trans("founders.foundersPosition")}</span>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Founders);