import React from 'react';
import './DetailPage.css';
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/footer/Footer";
import {getFooterRequest, getOfficesRequest, getSpacesRequest} from "../../networking/Requests";
import {withRouter} from "react-router-dom";
import {PlaceType} from "../../constants/PlaceType";
import OfficeAndSpaceDetail from "../../components/office-and-space-detail/OfficeAndSpaceDetail";
import Carousel from "../../components/carousel/Carousel";
import CardSlider from "../../components/slider/CardSlider";
import Card from "../../components/slider/Card";
import {SliderAlign} from "../../constants/SliderAlign";
import {getI18n, withTranslation} from "react-i18next";
import Decoration1 from "../../resources/images/page-decoration-1.svg";
import Decoration2 from "../../resources/images/page-decoration-2.svg";
import Loading from "../../components/loading/Loading";
import MetaTags from "react-meta-tags";
import RentersCarousel from "../../components/renters-carousel/RentersCarousel";

class DetailPage extends React.Component {

    state = {
        footerData: null,
        data: null,
        other: null,
        placeType: null,
    }

    constructor(props){
        super(props);
        if(props.lang && props.lang !== getI18n().language){
            getI18n().changeLanguage(this.props.lang);
        }
    }

    async componentDidMount() {
        const subUrl = this.props.match.params.subUrl;
        let data;
        let other;
        let placeType;

        Promise.all([
            getFooterRequest(),
            (this.props.type === PlaceType.OFFICE && getOfficesRequest()),
            (this.props.type === PlaceType.SPACE && getSpacesRequest()),
        ])
        .then(result => {
            const [footerData, officeData, spaceData] = result;

            if(officeData) {
                data = officeData.offices.find(office => office.detail.subUrl === subUrl);
                other = officeData.offices.filter((office) => {
                    return office.detail.subUrl !== subUrl;
                })
                placeType = PlaceType.OFFICE;
            }

            if(spaceData) {
                data = spaceData.spaces.find(space => space.detail.subUrl === subUrl);
                other = spaceData.spaces.filter((space) => {
                    return space.detail.subUrl !== subUrl;
                })
                placeType = PlaceType.SPACE;
            }

            this.setState({
                data: data,
                other: other,
                footerData: footerData,
                placeType: placeType
            });
        })
    }

    createCards(){
        let cardArray = [];
        this.state.other.forEach( (place) => {

            const priceData = {
                price: place.price,
                extraText: place.extraText
            }

            cardArray.push(
                <Card
                    key={place.id}
                    id={place.detail.subUrl}
                    title={place.title}
                    description={place.description}
                    imageUrl={place.imageUrl}
                    buttonText={place.buttonText}
                    priceData={priceData}
                    type={this.state.placeType}
                />
            )
        });
        return cardArray
    }

    render(){

        const trans = this.props.t;
        const {data, footerData} = this.state;

        if(!data || !footerData){
            return (
                <Loading />
            );
        }

        return(
            <div>

                <MetaTags>
                    <meta property="og:title" content={"De Dorus - " + data.detail.title}/>
                    <title>{"De Dorus - " + data.detail.title}</title>
                    <link rel="canonical" href="https://dedorus.nl/" />
                </MetaTags>

                <Navigation
                    {...this.props}
                    socials={footerData.socials}
                    detailPage={true}
                />

                <main>
                    <section className="office-and-space-detail-section">
                        <img id="decoration-1" src={Decoration1} alt="decoration"/>
                        <OfficeAndSpaceDetail
                            detailData={data.detail}
                            data={data}
                            carouselImages={data.imageSlider}
                        />
                    </section>

                    {data.detail.renters.length !== 0
                        ?
                        <section className="renters-section">
                            <img id="decoration-2" src={Decoration2} alt="decoration"/>
                            <Carousel renters={data.detail.renters} title={data.detail.rentersTitle}/>
                        </section>
                        :
                        null
                    }

                    <section className="other-options-section">
                        <img id="decoration-3" src={Decoration1} alt="decoration"/>
                        <CardSlider
                            cards={this.createCards()}
                            title={ this.state.placeType === PlaceType.OFFICE ? trans("place.otherOffices") : trans("place.otherSpaces")}
                            subTitle={trans("place.otherOptions")}
                            orientation={SliderAlign.RIGHT}
                        />
                    </section>
                </main>

                <Footer data={footerData}/>
            </div>
        );
    }
}

export default withTranslation()(withRouter(DetailPage));