import React from "react";
import ReactTypingEffect from 'react-typing-effect';
import './Header.css';
import {HashLink} from "react-router-hash-link";
import {getI18n} from "react-i18next";

class Header extends React.Component {

    setButtonLink(buttonUrl){
        let prefix = (getI18n().language === 'en') ? '/en/' : '/';
        if(buttonUrl){
            return prefix + buttonUrl;
        } else {
            return prefix + "#contact";
        }
    }

    render(){
        const {fixedTitle, description, buttonText, buttonURL, imageURL, mobileImageURL, dynamicTitles} = this.props

        return(
            <div className="header-background" style={{backgroundImage: `url('${imageURL}')`}}>
                <div className="header-box">
                    <div className="header-text-container">
                        <h1 className="header-fixed-title">{fixedTitle}</h1>
                        <ReactTypingEffect
                            className="header-animated-title"
                            cursorClassName="header-cursor"
                            text={dynamicTitles}
                            speed={75}
                            eraseSpeed={50}
                            eraseDelay={1200}
                            typingDelay={200}
                        />
                        <div className="header-description" dangerouslySetInnerHTML={{__html: description}}/>
                        <HashLink smooth to={this.setButtonLink(buttonURL)} className="header-button" >{buttonText}</HashLink>
                    </div>
                </div>
                <div className="header-mobile-image" style={{backgroundImage: `url('${mobileImageURL}')`}}/>
            </div>
        );
    }
}

export default Header