import React from "react";
import {getI18n} from "react-i18next";
import './LanguageSelector.css';
import { withRouter} from "react-router-dom";

class LanguageSelector extends React.Component {

    render(){

        const isNL = this.props.lang === 'nl';
        const mobile = this.props.mobile;

        const selectLanguage = (lang) => {
            if(this.props.mobile){
                let redirectPath;
                if(getI18n().language === 'en'){
                    redirectPath = constructPath('nl');
                } else {
                    redirectPath = constructPath('en');
                }
                this.props.onLanguageChanged(redirectPath)
            } else {
                let redirectPath;
                if(lang !== getI18n().language){
                    redirectPath = constructPath(lang);
                    this.props.onLanguageChanged(redirectPath);
                }
            }
        }

        const constructPath = (lang) => {
            const currentPath = this.props.location.pathname;
            if(lang === 'nl'){
                return currentPath.replace('/en', '')
            } else {
                return '/en' + currentPath
            }
        }

        if(!mobile){
            return(
                <div >
                    <span
                        className={"language-selector-item" + (isNL && !mobile ? " selected-language" : "")}
                        onClick={() => selectLanguage("nl")}>
                        NL
                    </span>
                    <span
                        className={"language-selector-item" + (isNL && !mobile ?  "" : " selected-language")}
                        onClick={() => selectLanguage("en")} >
                        EN
                    </span>
                </div>
            );
        } else {
            return(
                <div >
                    <span
                        className="language-selector-item"
                        onClick={() => selectLanguage()}>
                        {isNL ? "EN" : "NL"}
                    </span>
                </div>
            );
        }
    }
}

export default withRouter(LanguageSelector);