import React from "react";
import {
    getAboutHomePageRequest, getBlogsRequest,
    getContactRequest, getFooterRequest,
    getHeaderRequest,
    getOfficesRequest,
    getPromotionRequest,
    getSpacesRequest
} from "../../networking/Requests";
import {getI18n, withTranslation} from "react-i18next";
import Header from "../../components/header/Header";
import Navigation from "../../components/navigation/Navigation";
import VideoAndText from "../../components/video-and-text/VideoAndText"
import './HomePage.css'
import CardSlider from "../../components/slider/CardSlider";
import {SliderAlign} from "../../constants/SliderAlign";
import Card from "../../components/slider/Card";
import AppPromotion from "../../components/app-promotion/AppPromotion";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import Loading from "../../components/loading/Loading";
import {PlaceType} from "../../constants/PlaceType";
import Decoration1 from "../../resources/images/page-decoration-1.svg";
import Decoration2 from "../../resources/images/page-decoration-2.svg";
import Decoration4 from "../../resources/images/page-decoration-4.svg";
import Decoration5 from "../../resources/images/page-decoration-5.svg";
import MetaTags from 'react-meta-tags';

class HomePage extends React.Component {

    state = {
        headerData: null,
        aboutData: null,
        officesData: null,
        spacesData: null,
        promotionData: null,
        blogsData: null,
        contactData: null,
        footerData: null
    }

    constructor(props){
        super(props);
        if(props.lang && props.lang !== getI18n().language){
            getI18n().changeLanguage(this.props.lang);
        }
    }

    async componentDidMount() {
        Promise.all([
            getHeaderRequest(),
            getAboutHomePageRequest(),
            getOfficesRequest(),
            getSpacesRequest(),
            getPromotionRequest(),
            getBlogsRequest(3, 1),
            getContactRequest(),
            getFooterRequest()
        ])
        .then(result => {
            const [headerData, aboutData, officesData, spacesData, promotionData, blogsData, contactData, footerData] = result;
            this.setState({headerData, aboutData, officesData, spacesData, promotionData, blogsData, contactData, footerData});
        })
        .then(() => {
            let elementId = window.location.toString().split('#')[1]
            elementId && document.getElementById(elementId).scrollIntoView()
        })
    }

    createCards(placeType){
        let places;
        let cardArray = [];

        if(placeType === PlaceType.OFFICE){
            places = this.state.officesData.offices;
        } else if (placeType === PlaceType.SPACE) {
            places = this.state.spacesData.spaces;
        }

        places.forEach( place => {
            const priceData = {
                price: place.price,
                extraText: place.extraText
            }

            cardArray.push(
                <Card
                    key={place.id}
                    id={place.detail.subUrl}
                    title={place.title}
                    description={place.description}
                    imageUrl={place.imageUrl}
                    buttonText={place.buttonText}
                    priceData={priceData}
                    type={placeType}
                />
            )
        });

        return cardArray;
    }

    createBlogCards(){
        let cardArray = [];

        this.state.blogsData.blogPosts.forEach( blog => {
            const blogData = {
                date: blog.date,
                year: blog.year,
                month: blog.month,
                day: blog.day
            }

            cardArray.push(
                <Card
                    key={blog.id}
                    id={blog.slug}
                    title={blog.title}
                    description={blog.intro}
                    imageUrl={blog.attachment1}
                    buttonText={blog.buttonText}
                    type={'blog'}
                    blogData={blogData}
                />
            )
        });

        return cardArray;
    }

    render(){
        const {headerData, aboutData, officesData, spacesData, promotionData, blogsData, contactData, footerData} = this.state

        if(!headerData || !aboutData || !officesData || !spacesData || !promotionData || !contactData || !footerData){
            return (
                <Loading />
            );
        } else {
            return(
                <div>

                    <MetaTags>
                        <meta property="og:title" content="De Dorus - Creative Coworking Community"/>
                        <title>De Dorus - Creative Coworking Community</title>
                        <link rel="canonical" href="https://dedorus.nl/" />
                    </MetaTags>

                    <Navigation
                        {...this.props}
                        socials={footerData.socials}
                    />

                    <main>
                        <section id="home" className="homeSection">
                            <Header
                                fixedTitle={headerData.fixedTitle}
                                description={headerData.description}
                                buttonText={headerData.buttonText}
                                buttonURL={headerData.buttonURL}
                                imageURL={headerData.imageURL}
                                mobileImageURL={headerData.mobileImageURL}
                                dynamicTitles={headerData.titles}
                            />
                        </section>
                        <section id="about" className="aboutSection">
                            <img id="home-page-decoration-1" src={Decoration5} alt="decoration"/>
                            <VideoAndText
                                title={aboutData.title}
                                description={aboutData.description}
                                media={aboutData.attachment}
                            />
                        </section>
                        <section id="blogs" className="blogsSection">
                            <CardSlider
                                cards={this.createBlogCards()}
                                title={blogsData.title}
                                subTitle={blogsData.subtitle}
                                description={''}
                                orientation={SliderAlign.RIGHT}
                                color="#F29E4D"
                                decoration={true}
                                type={'blogs'}
                                buttonText={blogsData.sliderButtonText}
                            />
                        </section>
                        <section id="offices" className="officesSection">
                            <img id="home-page-decoration-2" src={Decoration2} alt="decoration"/>
                            <img id="home-page-decoration-3" src={Decoration1} alt="decoration"/>
                            <CardSlider
                                cards={this.createCards(PlaceType.OFFICE)}
                                title={officesData.title}
                                subTitle={officesData.subTitle}
                                description={officesData.description}
                                orientation={SliderAlign.LEFT}
                            />
                        </section>
                        <section id="spaces" className="spacesSection">
                            <CardSlider
                                cards={this.createCards(PlaceType.SPACE)}
                                title={spacesData.title}
                                subTitle={spacesData.subTitle}
                                description={spacesData.description}
                                orientation={SliderAlign.RIGHT}
                                color="#CBDFEF"
                                decoration={true}
                            />
                        </section>
                        <section id="app" className="appSection">
                            <AppPromotion
                                title={promotionData.title}
                                description={promotionData.description}
                                imageUrl={promotionData.imageUrl}
                                appStoreUrl={promotionData.appStoreUrl}
                                playStoreUrl={promotionData.playStoreUrl}
                            />
                        </section>
                        <section id="contact" className="contactSection">
                            <img id="home-page-decoration-5" src={Decoration4} alt="decoration"/>
                            <Contact
                                title={contactData.title}
                                address={contactData.address}
                                email={contactData.email}
                                phone={contactData.phone}
                            />
                        </section>
                    </main>
                    <Footer data={footerData}/>
                </div>
            );
        }
    }
}

export default withTranslation()(HomePage);