import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

class Loading extends React.Component {

    render(){
        return(
            <div className="loading-screen">
                <div className="loader-container">
                    <Loader className="loader" type="Grid" height={120} width={120} color="#FFFFFF"/>
                    <span className="loader-text">Loading...</span>
                </div>
            </div>
        );
    }
}

export default Loading;