import {getI18n} from "react-i18next";
import axios from "axios";

const {REACT_APP_API_BASE_URL} = process.env;

export const getHeaderRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "header"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {
            return {
                fixedTitle: data.fixedTitle,
                titles: data.titles,
                description: data.description,
                buttonText: data.buttonText,
                buttonURL: data.buttonURL,
                imageURL: data.imageURL,
                mobileImageURL: data.mobileImageURL
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getAboutHomePageRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "about"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});
        if (data) {
            return {
                title: data.title,
                description: data.description,
                attachment: data.attachment,
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getOfficesRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "offices"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});


        if (data) {
            const offices = [];
            data.workplaces.forEach(office => {
               offices.push(
                   {
                       id: office.id,
                       title: office.title,
                       price: office.price,
                       extraText: office.extraText,
                       description: office.description,
                       buttonText: office.buttonText,
                       buttonTextURL: office.buttonTextURL,
                       imageUrl: office.imageUrl,
                       sortOrder: office.sortOrder,
                       detail: office.detail,
                       imageSlider: office.imageSlider
                   }
               )
            });

            return {
                title: data.title,
                subTitle: data.subTitle,
                description: data.description,
                offices: offices
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getSpacesRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "spaces"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});


        if (data) {
            const spaces = [];
            data.workplaces.forEach(space => {
                spaces.push(
                    {
                        id: space.id,
                        title: space.title,
                        price: space.price,
                        extraText: space.extraText,
                        description: space.description,
                        buttonText: space.buttonText,
                        buttonTextURL: space.buttonTextURL,
                        imageUrl: space.imageUrl,
                        sortOrder: space.sortOrder,
                        detail: space.detail,
                        imageSlider: space.imageSlider
                    }
                )
            });

            return {
                title: data.title,
                subTitle: data.subTitle,
                description: data.description,
                spaces: spaces
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getPromotionRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "promotion"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {
            return {
                title: data.title,
                description: data.description,
                imageUrl: data.imageUrl,
                appStoreUrl: data.appStoreUrl,
                playStoreUrl: data.playStoreUrl
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getBlogsRequest = async (size, page) => {
    const url = REACT_APP_API_BASE_URL + "blogs"

    try {
        const {data} = await axios.get(url, {params: {
            lang: getI18n().language,
            ...(typeof page != "undefined" ? { 'page[size]': size, 'page[number]': page } : {})
        }});

        if (data) {
            const blogPosts = [];
            data.blogPosts.forEach(blogPost => {
                blogPosts.push(
                    {
                        id: blogPost.id,
                        slug: blogPost.slug,
                        title: blogPost.title,
                        date: blogPost.date,
                        year: blogPost.year,
                        month: blogPost.month,
                        day: blogPost.day,
                        intro: blogPost.intro,
                        attachment1: blogPost.attachment1,
                        attachment2: blogPost.attachment2,
                        attachment3: blogPost.attachment3,
                        attachment4: blogPost.attachment4,
                        attachment5: blogPost.attachment5,
                        subtitle: blogPost.subtitle,
                        description: blogPost.description,
                        buttonText: blogPost.buttonText
                    }
                )
            });

            return {
                title: data.title,
                subtitle: data.subtitle,
                detailTitle: data.detailTitle,
                detailDescription: data.detailDescription,
                backButtonText: data.backButtonText,
                sliderButtonText: data.sliderButtonText,
                relatedTitle: data.relatedTitle,
                relatedSubtitle: data.relatedSubtitle,
                totalPages: data.totalPages,
                blogPosts: blogPosts
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getBlogPostRequest = async (slug) => {
    const url = REACT_APP_API_BASE_URL + "blogs/" + slug

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {
            return {
                id: data.id,
                slug: data.slug,
                title: data.title,
                date: data.date,
                year: data.year,
                month: data.month,
                day: data.day,
                intro: data.intro,
                attachment1: data.attachment1,
                attachment2: data.attachment2,
                attachment3: data.attachment3,
                attachment4: data.attachment4,
                attachment5: data.attachment5,
                subtitle: data.subtitle,
                description: data.description,
                buttonText: data.buttonText
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getContactRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "contact"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {
            return {
                title: data.title,
                address: data.address,
                email: data.email,
                phone: data.phone,
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getFooterRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "footer"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {
            const socials = [];
            data.socials.forEach(social => {
                socials.push(
                    {
                        type: social.type,
                        url: social.url
                    }
                )
            });
            return {
                socials: socials,
                info: {
                    appStoreUrl: data.info.appStoreUrl,
                    playStoreUrl: data.info.playStoreUrl,
                    address: data.info.address,
                    email: data.info.email,
                    phone: data.info.phone
                }
            }
        }
    } catch (e) {
        console.log("failed")
    }
}

export const getAboutRequest = async () => {
    const url = REACT_APP_API_BASE_URL + "about"

    try {
        const {data} = await axios.get(url, {params: {lang: getI18n().language}});

        if (data) {

            const renters = [];
            data.detail.renters.forEach(renter => {
                renters.push(
                    {
                        id: renter.id,
                        name: renter.name,
                        position: renter.position,
                        company: renter.company,
                        review: renter.review,
                        facebook: renter.facebook,
                        twitter: renter.twitter,
                        linkedIn: renter.linkedIn,
                        instagram: renter.instagram,
                        website: renter.website,
                        imageUrl: renter.imageUrl,
                        profilePictureUrl: renter.profilePictureUrl
                    }
                )
            });

            const impressions = [];
            data.detail.impressions.forEach(impression => {
                impressions.push(
                    {
                        id: impression.id,
                        title: impression.title,
                        description: impression.description,
                        imageUrl: impression.imageUrl
                    }
                )
            })

            return {
                title: data.title,
                description: data.description,
                attachment: data.attachment,
                detail: {
                    attachment1: data.detail.attachment1,
                    attachment2: data.detail.attachment2,
                    attachment3: data.detail.attachment3,
                    attachment4: data.detail.attachment4,
                    attachment5: data.detail.attachment5,
                    title: data.detail.title,
                    description: data.detail.description,
                    quote: data.detail.quote,
                    image1: data.detail.image1,
                    image2: data.detail.image2,
                    image3: data.detail.image3,
                    subTitle: data.detail.subTitle,
                    subDescription: data.detail.subDescription,
                    tenantsTitle: data.detail.tenantsTitle,
                    tenantsDescription: data.detail.tenantsDescription,
                    renters: renters,
                    impressions: impressions
                }
            }
        }
    } catch (e) {
        console.log("failed")
    }
}
