import React from 'react'
import './AppPromotion.css'
import AppStoreBadge from '../../resources/images/footer-app-store-badge.svg'
import PlayStoreBadge from '../../resources/images/footer-play-store-badge.svg'
import Triangles from '../../resources/images/promotion-decoration-1.svg'
import DottedTriangles from '../../resources/images/promotion-decoration-2.svg'
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class AppPromotion extends React.Component {

    render(){

        const {title, description, imageUrl, appStoreUrl, playStoreUrl} = this.props;
        const trans = this.props.t

        return(
            <div className="app-promotion-wrapper">
                <div className="app-promotion-container">
                    <div className="app-promotion-text-app-links-container">
                        <div className="app-promotion-text-container">
                            <h2 className="app-promotion-title">{title}</h2>
                            <div className="app-promotion-description" dangerouslySetInnerHTML={{__html: description}}/>
                        </div>
                        <div>
                            <span className="app-promotion-app-links-title">{trans("promotion.appLinksTitle")}</span>
                            <div className="app-promotion-app-links-container">
                                <Link to={{pathname: playStoreUrl}} target="_blank">
                                    <img src={PlayStoreBadge} alt="Play Store badge"/>
                                </Link>
                                <Link to={{pathname: appStoreUrl}} target="_blank">
                                    <img src={AppStoreBadge} alt="App Store badge"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="app-promotion-image-container">
                        <img className="app-promotion-image" src={imageUrl} alt=""/>
                        <img className="app-promotion-decoration-2" src={DottedTriangles} alt="decoration"/>
                        <img className="app-promotion-decoration-3" src={DottedTriangles} alt="decoration"/>
                    </div>
                    <img className="app-promotion-decoration-1" src={Triangles} alt="decoration"/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AppPromotion);