import React from 'react';
import './OfficeAndSpaceDetail.css';
import {getI18n, withTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";
import MediaCarousel from "../media-carousel/MediaCarousel";

class OfficeAndSpaceDetail extends React.Component{

    setButtonLink(buttonUrl){
        let prefix = (getI18n().language === 'en') ? '/en/' : '/';
        if(buttonUrl){
            return prefix + buttonUrl;
        } else {
            return prefix + "#contact";
        }
    }

    render() {

        const trans = this.props.t;
        const {
            title, description, subTitle, subDescription, imageUrl,
            buttonText, buttonUrl, features1, features2
        } = this.props.detailData;

        const { price } = this.props.data;

        const {
            attachment1,
            attachment2,
            attachment3,
            attachment4,
            attachment5,
        } = this.props.carouselImages

        const anyAttachmentNotNull =
            attachment1 !== null ||
            attachment2 !== null ||
            attachment3 !== null ||
            attachment4 !== null ||
            attachment5 !== null;


        return (

            <div className="place-wrapper">

                <div className="mobile-yellow-header"/>

                <div className="place-container">
                    <div className="place-text-container">
                        <h1 className="place-title">{title}</h1>
                        <div className={"place-price"}>
                            <p className={"place-price-from"}>{trans("place.pricePrefix")}: </p>
                            <p className={"place-price-amount"}>€{price}{trans("place.priceSuffix")}</p>
                        </div>
                        <div className="place-description" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>

                    <div className="place-sub-description-container">
                        <div className="place-image-container">
                            <img className="place-image" src={imageUrl} alt=""/>
                        </div>

                        <div className="place-sub-description-text-container">
                            <h2 className="place-sub-title">{subTitle}</h2>
                            <div className="place-sub-description" dangerouslySetInnerHTML={{__html: subDescription}}/>
                            <HashLink className="place-sub-description-button" to={this.setButtonLink(buttonUrl)}>{buttonText}</HashLink>
                        </div>
                    </div>

                    <div className="place-features-container">
                        <div className="place-feature-container">
                            <h3 className="place-feature-title">{trans("place.feature1Title")}</h3>
                            <div className="place-features" dangerouslySetInnerHTML={{__html: features1}}/>
                        </div>

                        <div className="place-feature-container">
                            <h3 className="place-feature-title">{trans("place.feature2Title")}</h3>
                            <div className="place-features" dangerouslySetInnerHTML={{__html: features2}}/>
                        </div>
                    </div>

                    { anyAttachmentNotNull && (
                        <div className="media-carousel-section">
                            <MediaCarousel
                                media={[
                                    attachment1,
                                    attachment2,
                                    attachment3,
                                    attachment4,
                                    attachment5,
                                ]}/>
                        </div>
                    )}


                    <HashLink className="place-sub-description-button-mobile" to={this.setButtonLink(buttonUrl)}>{buttonText}</HashLink>
                </div>

                <div className="place-gradient"/>

            </div>
        );
    }
}

export default withTranslation()(OfficeAndSpaceDetail);