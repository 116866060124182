import React from "react";
import {getI18n, withTranslation} from "react-i18next";
import './Navigation.css';
import Logo from '../../../src/resources/images/logo.svg'
import LanguageSelector from "../language-selector/LanguageSelector";
import {Link, Redirect} from "react-router-dom";
import { Squash as Hamburger } from 'hamburger-react'
import {SocialType} from "../../constants/SocialType";
import Facebook from "../../resources/images/ic-facebook-dark.svg";
import Instagram from "../../resources/images/ic-instagram-dark.svg";
import LinkedIn from "../../resources/images/ic-linkedin-dark.svg";
import Twitter from "../../resources/images/ic-twitter-dark.svg";
import {NavHashLink} from 'react-router-hash-link';
import Decoration from "../../resources/images/mobile-menu-decoration.svg"

class Navigation extends React.Component {

    state = {
        redirect: null,
        isMenuOpen: false,
        heightSet: 0
    }

    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        if(this.props.detailPage){
            this.updateDimensions();
            window.addEventListener('scroll', this.updateDimensions);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateDimensions);
    }

    updateDimensions() {
        this.setState({ heightSet: window.scrollY });
    }

    createSocials(socials){
        const socialsArray = [];
        socials.forEach(social => {
            if(social.url){
                socialsArray.push(
                    <Link key={social.type} to={{pathname: social.url}} target="_blank">
                        {this.getSocialImage(social.type)}
                    </Link>
                );
            }
        });
        return socialsArray;
    }

    getSocialImage(socialType){
        switch(socialType){
            case SocialType.FACEBOOK:
                return <img className="navigation-social-icon" src={Facebook} alt="Facebook logo"/>;
            case SocialType.INSTAGRAM:
                return <img src={Instagram} alt="Instagram logo"/>;
            case SocialType.LINKEDIN:
                return <img src={LinkedIn} alt="LinkedIn logo"/>;
            case SocialType.TWITTER:
                return <img src={Twitter} alt="Twitter logo"/>;
            default: return null;
        }
    }

    handleLanguageChange(redirectPath){
        this.closeMenu();
        this.setState({redirect: redirectPath});
    }

    closeMenu(){
        document.documentElement.style.setProperty('overflow-y', "scroll");
        this.setState({isMenuOpen: false});
    }

    render(){
        const socials = this.props.socials;
        const trans = this.props.t;
        let langIsNL = getI18n().language === 'nl';

        const scrollWithOffset = (el) => {
            const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -110;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }

        if(this.state.redirect){
            return (
                <Redirect to={this.state.redirect}/>
            );
        }

        return(
            <div className={"navigation-container" + (this.props.detailPage && this.state.heightSet === 0 ? " mobile-detail-screen" : "")}>

                <nav className={"navigation" + (this.state.isMenuOpen ? " menu-open" : "")}  style={ this.state.isMenuOpen ? {'height': window.innerHeight} : {}}>
                    {this.state.isMenuOpen
                        ?
                        <img className="navigation-mobile-decoration" src={Decoration} alt="decoration"/>
                        :
                        null
                    }

                    <ul className="navigation-list">

                        {/*<li>*/}
                        {/*    <NavHashLink*/}
                        {/*        className="navigation-list-item"*/}
                        {/*        activeClassName="navigation-list-item-selected"*/}
                        {/*        smooth to={"/" + (langIsNL ? "#home" : "en/#home")}*/}
                        {/*        scroll={el => scrollWithOffset(el)}*/}
                        {/*        onClick={this.closeMenu}>*/}
                        {/*        {trans("navigation.home")}*/}
                        {/*    </NavHashLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#about"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.about")}
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#blogs"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.blogs")}
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#offices"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.offices")}
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#spaces"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.spaces")}
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#app"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.app")}
                            </NavHashLink>
                        </li>
                        <li>
                            <NavHashLink
                                className="navigation-list-item"
                                activeClassName="navigation-list-item-selected"
                                smooth to={"/" + (langIsNL ? "" : "en/") + "#contact"}
                                scroll={el => scrollWithOffset(el)}
                                onClick={this.closeMenu}>
                                {trans("navigation.contact")}
                            </NavHashLink>
                        </li>
                    </ul>

                    {this.createSocials(socials).length !== 0
                        ?
                        <div className="navigation-socials-container">
                            <div className="navigation-socials-title">{trans("navigation.socialsTitle")}</div>
                            <div className="navigation-socials-icons">
                                {this.createSocials(socials)}
                            </div>
                        </div>
                        :
                        null
                    }

                </nav>

                <a href={"/" + (langIsNL ? "" : "en/")}>
                    <img className="logo" src={Logo} alt="logo"/>
                </a>

                <div className="navigation-language-selector">
                    <LanguageSelector onLanguageChanged={this.handleLanguageChange} {...this.props}/>
                </div>

                <div className="navigation-language-selector-mobile">
                    <LanguageSelector onLanguageChanged={this.handleLanguageChange} {...this.props} mobile={true}/>
                </div>

                <Hamburger duration={0.2} rounded={true} toggled={this.state.isMenuOpen} onToggle={toggled => {
                    if(toggled){
                        this.setState({isMenuOpen: true});
                        document.documentElement.style.setProperty('overflow-y', "hidden");
                    } else {
                        this.setState({isMenuOpen: false});
                        document.documentElement.style.setProperty('overflow-y', "scroll");
                    }
                }}/>
            </div>
        );
    }
}

export default withTranslation()(Navigation);