import React from "react"
import {getI18n, withTranslation} from "react-i18next";
import './VideoAndText.css'
import Arrow from './../../resources/images/arrow-right.svg'
import {Link} from "react-router-dom";
import {VideoExtensions} from "../../constants/VideoExtensions";

class VideoAndText extends React.Component {

    isMediaTypeVideo(media) {
        let mediaIsVideo = false
        VideoExtensions.forEach(extension => {
            if (media.toLowerCase().includes(extension)) {
               mediaIsVideo = true;
            }
        });
        return mediaIsVideo;
    }

    render() {

        const {title, description, media} = this.props;
        const trans = this.props.t;

        return (
            <div className="video-and-text-wrapper">
                <div className="video-and-text-container">
                    {this.isMediaTypeVideo(media)
                        ?
                        <video
                            className="video-and-text-video"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={media}/>
                        </video>
                        :
                        <img className="video-and-text-image" src={media} alt=""/>
                    }
                    <div className="video-and-text-text-container">
                        <h2>{title}</h2>
                        <div className="video-text-description" dangerouslySetInnerHTML={{__html: description}}/>
                        <Link className="video-and-text-button" to={getI18n().language === 'en' ? "/en/about" : "/about"}>
                            <span>{trans("videoAndText.buttonText")}</span>
                            <img src={Arrow} alt="arrow-right"/>
                        </Link>
                    </div>
                </div>
            </div>
        );

    }
}

export default withTranslation()(VideoAndText)