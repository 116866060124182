import React from "react";
import './CardSlider.css';
import ScrollContainer from 'react-indiana-drag-scroll';
import Decoration from "../../resources/images/page-decoration-3.svg";
import BlogsDecoration from "../../resources/images/page-decoration-9.svg";
import Arrow from "../../resources/images/arrow-right.svg";
import {getI18n} from "react-i18next";

class CardSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth <= 670, // Set the initial state based on your breakpoint
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.setState({ isMobile: window.innerWidth <= 670 }); // Update state on resize
    }

    getRedirectPath(){
        let redirectPath = "";

        if(getI18n().language === 'en'){
            redirectPath += "/en/";
        } else {
            redirectPath += "/";
        }

        if (this.props.type === 'blogs') {
            redirectPath += "blogs";
        }

        return redirectPath;
    }

    render() {
        const {title, subTitle, description, cards, orientation, color, decoration, type, buttonText} = this.props;
        const { isMobile } = this.state;

        return (
            <div
                className={"card-slider-container" + (orientation ? ` ${orientation}` : "")}
                style={color ? {backgroundColor: color} : {}}>

                { decoration
                    ?
                    <img id={`card-slider-decoration${type === 'blogs' && '-blogs'}`} src={type === 'blogs' ? BlogsDecoration : Decoration} alt="decoration"/>
                    :
                    null
                }

                <div className="card-slider-text-container">
                    <h3 className="card-slider-subtitle">{subTitle}</h3>
                    <h2 className="card-slider-title">{title}</h2>
                    <div className="card-slider-description" dangerouslySetInnerHTML={{__html: description}}/>
                    { type === 'blogs' && !isMobile
                        ?
                        <a className="card-button" href={this.getRedirectPath()}>
                            <span>{buttonText}</span>
                            <img src={Arrow} alt="arrow-right"/>
                        </a>
                        :
                        null
                    }
                </div>
                <ScrollContainer
                    className="card-slider-cards-container"
                    vertical = {false}
                >{cards}</ScrollContainer>

                { isMobile && type === 'blogs' &&
                    <div className="card-slider-text-container">
                        <a className="card-button" href={this.getRedirectPath()}>
                            <span>{buttonText}</span>
                            <img src={Arrow} alt="arrow-right" />
                        </a>
                    </div>
                }

            </div>
        );

    }
}

export default CardSlider;