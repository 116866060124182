import React from "react";
import './App.css';
import './resources/Colors.css'
import {
  BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import HomePage from "./pages/homepage/HomePage";
import AboutPage from "./pages/aboutpage/AboutPage";
import DetailPage from "./pages/detailpage/DetailPage";
import {PlaceType} from "./constants/PlaceType";
import BlogPage from "./pages/blogpage/BlogPage";
import BlogsOverview from "./pages/blogsoverview/BlogsOverview";


class App extends React.Component {

    render() {

        return (

            <Router>
                <Switch>
                    <Route exact path="/" component={ () => <HomePage lang="nl"/> } />
                    <Route exact path="/en/" component={ () => <HomePage lang="en"/> } />

                    <Route exact path={"/about"} component={ () => <AboutPage lang="nl"/> } />
                    <Route exact path={"/en/about"} component={ () => <AboutPage lang="en"/> } />

                    <Route exact path={"/spaces/:subUrl"} component={ () => <DetailPage lang="nl" type={PlaceType.SPACE}/> } />
                    <Route exact path={"/en/spaces/:subUrl"} component={ () => <DetailPage lang="en" type={PlaceType.SPACE}/> } />

                    <Route exact path={"/offices/:subUrl"} component={ () => <DetailPage lang="nl" type={PlaceType.OFFICE}/> } />
                    <Route exact path={"/en/offices/:subUrl"} component={ () => <DetailPage lang="en" type={PlaceType.OFFICE}/> } />

                    <Route exact path={"/blogs"} component={ (props) => <BlogsOverview lang="nl" {...props}/> } />
                    <Route exact path={"/en/blogs"} component={ (props) => <BlogsOverview lang="en" {...props}/> } />
                    <Route exact path={"/blogs/:slug"} component={ (props) => <BlogPage lang="nl" {...props}/> } />
                    <Route exact path={"/en/blogs/:slug"} component={ (props) => <BlogPage lang="en" {...props}/> } />
                </Switch>
            </Router>
        );
    }
}

export default withTranslation()(App);
