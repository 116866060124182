import React from 'react';
import './Footer.css';
import Logo from '../../resources/images/logo-white.svg';
import Facebook from '../../resources/images/ic-facebook.svg';
import Instagram from '../../resources/images/ic-instagram.svg';
import LinkedIn from '../../resources/images/ic-linkedin.svg';
import Twitter from '../../resources/images/ic-twitter.svg';
import AppStoreBadge from '../../resources/images/footer-app-store-badge.svg'
import PlayStoreBadge from '../../resources/images/footer-play-store-badge.svg'
import {SocialType} from "../../constants/SocialType";
import {getI18n, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class Footer extends React.Component {

    createSocials(socials){
        const socialsArray = [];
        socials.forEach(social => {
            if(social.url){
                socialsArray.push(
                    <Link key={social.type} to={{pathname: social.url}} target="_blank">
                        {this.getSocialImage(social.type)}
                    </Link>
                );
            }
        });
        return socialsArray;
    }

    getSocialImage(socialType){
        switch(socialType){
            case SocialType.FACEBOOK:
                return <img src={Facebook} alt="Facebook logo"/>;
            case SocialType.INSTAGRAM:
                return <img src={Instagram} alt="Instagram logo"/>;
            case SocialType.LINKEDIN:
                return <img src={LinkedIn} alt="LinkedIn logo"/>;
            case SocialType.TWITTER:
                return <img src={Twitter} alt="Twitter logo"/>;
            default: return null
        }
    }

    getAddressWithLineBreaks(address){
        return address.split(',').map(str => <span key={str} className="footer-address-text">{str.trim()}</span>)
    }

    render() {

        const socials = this.props.data.socials;
        const info = this.props.data.info;
        const trans = this.props.t;
        let langIsNL = getI18n().language === 'nl';

        return (
          <footer className="footer-background">

              <div className="footer-logo-container">
                  <a href={langIsNL ? '/' : '/en/'}>
                      <img className="footer-logo" src={Logo} alt="De Dorus logo"/>
                  </a>
              </div>

            <div className="footer-grid">

                <div className="footer-grid-item">
                    <span className="footer-grid-item-title">{trans("footer.socialsTitle")}</span>
                    <div className="footer-socials-container">
                        {this.createSocials(socials)}
                    </div>
                </div>

                <div className="footer-grid-item">
                    <span className="footer-grid-item-title">{trans("footer.appLinksTitle")}</span>
                    <div className="footer-app-links-container">
                        <Link to={{pathname: info.appStoreUrl}} target="_blank">
                            <img src={AppStoreBadge} alt="App Store badge"/>
                        </Link>

                        <Link to={{pathname: info.playStoreUrl}} target="_blank">
                            <img src={PlayStoreBadge} alt="Play Store badge"/>
                        </Link>
                    </div>
                </div>

                <div className="footer-grid-item">
                    <span className="footer-grid-item-title">{trans("footer.addressTitle")}</span>
                    <address className="footer-address-container">
                        {this.getAddressWithLineBreaks(info.address)}
                    </address>
                </div>

                <div className="footer-grid-item">
                    <span className="footer-grid-item-title">{trans("footer.infoTitle")}</span>
                    <address className="footer-info-container">
                        <a className="footer-address-text" href={"mailto:" + info.email}>{info.email}</a>
                        <a className="footer-address-text footer-phone-number" href={"tel:" + info.phone}>{info.phone}</a>
                    </address>
                </div>

            </div>
          </footer>
        );
    }
}

export default withTranslation()(Footer);
