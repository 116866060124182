import React from 'react';
import './RentersCarousel.css';
import Facebook from "../../resources/images/ic-facebook-dark.svg";
import Twitter from "../../resources/images/ic-twitter-dark.svg";
import LinkedIn from "../../resources/images/ic-linkedin-dark.svg";
import Instagram from "../../resources/images/ic-instagram-dark.svg";
import Website from "../../resources/images/ic-website-dark.svg";
import {Link} from "react-router-dom";

class RentersCarouselCard extends React.Component{

    render() {

        const {profilePictureUrl, name, position, review, facebook, twitter, linkedIn, instagram, website} = this.props;

        return (
            <div className="renters-carousel-card">
                <div className="renter-carousel-card-header">
                    <img className="renter-carousel-card-header-image" src={profilePictureUrl} alt={`Renter ${name}`}/>
                    <div className="renter-carousel-card-header-text-container">
                        <h3 className="renter-carousel-card-renter-name">{name}</h3>
                        <h4 className="renter-carousel-card-renter-position">{position}</h4>
                    </div>
                </div>

                <div className="renters-carousel-card-text" dangerouslySetInnerHTML={{__html: review}}/>

                <div className="renters-carousel-card-socials-container">

                    {facebook &&
                        <Link to={{pathname: facebook}} target="_blank">
                            <img src={Facebook} alt="Facebook icon"/>
                        </Link>
                    }

                    {twitter &&
                        <Link to={{pathname: twitter}} target="_blank">
                            <img src={Twitter} alt="Twitter icon"/>
                        </Link>
                    }

                    {linkedIn &&
                        <Link to={{pathname: linkedIn}} target="_blank">
                            <img src={LinkedIn} alt="LinkedIn icon"/>
                        </Link>
                    }

                    {instagram &&
                        <Link to={{pathname: instagram}} target="_blank">
                            <img src={Instagram} alt="Instagram icon"/>
                        </Link>
                    }

                    {website &&
                        <Link to={{pathname: website}} target="_blank">
                            <img src={Website} alt="Internet icon"/>
                        </Link>
                    }

                </div>
            </div>
        );
    }
}
export default RentersCarouselCard;