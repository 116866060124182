import React from 'react';
import './Contact.css'
import Decoration from '../../resources/images/decoration-contact.svg'
import {withTranslation} from "react-i18next";
import Marker from '../../resources/images/contact-map-marker.svg';
import MarkerText from '../../resources/images/contact-map-marker-text.svg';
import {Link} from 'react-router-dom'

class Contact extends React.Component {


    getAddressWithLineBreaks(address){
        return address.split(',').map(str => <span key={str} className="footer-address-text">{str.trim()}</span>)
    }

    render() {

        const {title, address, email, phone} = this.props;
        const trans = this.props.t

        return (
            <div className="contact-background">
                <div className="contact-wrapper">
                    <div className="contact-container">
                        <div className="contact-text-container">
                            <address>
                                <h2 className="contact-title">{title}</h2>
                                <h3 className="contact-address-title">{trans("contact.addressTitle")}</h3>
                                <span className="contact-address">{this.getAddressWithLineBreaks(address)}</span>
                                <Link className="contact-route" to={{pathname: "https://www.google.com/maps/place/De+Dorus/@52.1594546,4.5138572,15z/data=!4m5!3m4!1s0x0:0x5b2a98e0f6607b84!8m2!3d52.1594578!4d4.5138312"}} target="_blank">{trans("contact.route")}</Link>
                                {/*<a className="contact-route" href="https://www.google.com/maps/place/De+Dorus/@52.1594546,4.5138572,15z/data=!4m5!3m4!1s0x0:0x5b2a98e0f6607b84!8m2!3d52.1594578!4d4.5138312">{trans("contact.route")}</a>*/}
                                <h3 className="contact-info-title">{trans("contact.infoTitle")}</h3>
                                <a className="contact-info" href={"mailto:" + email}>{email}</a>
                                <a className="contact-info" href={"tel:" + phone}>{phone}</a>
                            </address>
                        </div>
                        <img className="contact-decoration" src={Decoration} alt="decoration"/>
                    </div>
                </div>
                
                <div className="contact-marker-wrapper">
                    <div className="contact-marker-container">
                        <Link to={{pathname: "https://www.google.com/maps/place/De+Dorus/@52.1594546,4.5138572,15z/data=!4m5!3m4!1s0x0:0x5b2a98e0f6607b84!8m2!3d52.1594578!4d4.5138312"}} target="_blank">
                            <img className="contact-marker" src={Marker} alt="Marker"/>
                            <img className="contact-marker-text" src={MarkerText} alt="We are here"/>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Contact);