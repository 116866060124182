import React from 'react';
import './Carousel.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Facebook from "../../resources/images/ic-facebook-dark.svg";
import Twitter from "../../resources/images/ic-twitter-dark.svg";
import Instagram from "../../resources/images/ic-instagram-dark.svg";
import LinkedIn from "../../resources/images/ic-linkedin-dark.svg";
import Website from "../../resources/images/ic-website-dark.svg";
import NextButton from "../../resources/images/carousel-next-btn.svg";
import PrevButton from "../../resources/images/carousel-previous-btn.svg"
import {Link} from "react-router-dom";

function NextArrow(props) {
    const {onClick} = props;
    return(
        <img className="carousel-next-button" src={NextButton} alt="Carousel next button" onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {onClick} = props;
    return(
        <img className="carousel-prev-button"  src={PrevButton} alt="Carousel previous button" onClick={onClick}/>
    );
}

class Carousel extends React.Component{

    render() {

        const {title, renters} = this.props;
        const carouselSettings = {
            nextArrow: window.innerWidth >= 768 ? <NextArrow /> : null,
            prevArrow: window.innerWidth >= 768 ? <PrevArrow /> : null,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '30px'
        };

        return (
            <div>

                <h2 className="carousel-title">{title}</h2>

                <Slider {...carouselSettings}>

                    {renters.map((renter) => {
                        return <div className="carousel-slide-wrapper" key={renter.id}>
                            <div className="carousel-slide-container">

                                <div className="carousel-slide-left">

                                    <div className="carousel-slide-left-text-container">
                                        <h3 className="carousel-slide-title">{renter.company}</h3>
                                        <div className="carousel-slide-left-review" dangerouslySetInnerHTML={{__html: renter.review}}/>
                                    </div>

                                    <div className="carousel-slide-left-renter-container">
                                        <img className="carousel-slide-left-image" src={renter.profilePictureUrl} alt=""/>
                                        <div className="carousel-slide-left-renter-info-container">
                                            <h4 className="carousel-slide-left-renter-name">{renter.name}</h4>
                                            <span className="carousel-slide-left-renter-function">{renter.position}</span>

                                            <div className="carousel-slide-left-socials-container">
                                                {renter.facebook ?
                                                    <Link to={{pathname: renter.facebook}} target="_blank">
                                                        <img src={Facebook} alt="Facebook icon"/>
                                                    </Link> : null
                                                }

                                                {renter.twitter ?
                                                    <Link to={{pathname: renter.twitter}} target="_blank">
                                                        <img src={Twitter} alt="Twitter icon"/>
                                                    </Link> : null
                                                }

                                                {renter.linkedIn ?
                                                    <Link to={{pathname: renter.linkedIn}} target="_blank">
                                                        <img src={LinkedIn} alt="LinkedIn icon"/>
                                                    </Link> : null
                                                }

                                                {renter.instagram ?
                                                    <Link to={{pathname: renter.instagram}} target="_blank">
                                                        <img src={Instagram} alt="Instagram icon"/>
                                                    </Link> : null
                                                }

                                                {renter.website ?
                                                    <Link to={{pathname: renter.website}} target="_blank">
                                                        <img src={Website} alt="Internet icon"/>
                                                    </Link> : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-slide-right">
                                    <img className="carousel-slide-right-image" src={renter.imageUrl} alt=""/>
                                </div>

                            </div>
                        </div>
                    })}

                </Slider>
            </div>
        );
    }
}

export default Carousel;