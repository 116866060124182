import React from "react";
import './BlogsOverview.css';
import Navigation from "../../components/navigation/Navigation";
import {getBlogsRequest, getFooterRequest} from "../../networking/Requests";
import Footer from "../../components/footer/Footer";
import {getI18n, withTranslation} from "react-i18next";
import Loading from "../../components/loading/Loading";
import MetaTags from "react-meta-tags";
import Card from "../../components/slider/Card";
import ArrowDown from "../../resources/images/ic-arrow-down.svg";

class BlogsOverview extends React.Component {

    state = {};

    constructor(props){
        super(props);
        if(props.lang && props.lang !== getI18n().language){
            getI18n().changeLanguage(this.props.lang);
        }
    }

    async componentDidMount() {
        Promise.all([
            getFooterRequest(),
            getBlogsRequest(6, 1)
        ])
        .then(result => {
            const [footerData, blogsData] = result;

            this.setState({
                totalPages: blogsData.totalPages,
                pageNumber: 2,
                footerData: footerData,
                blogsData: blogsData
            });
        })
    }

    async showMore(){
        const blogsData = await getBlogsRequest(6, this.state.pageNumber);
        let prevPageNumber = this.state.pageNumber;
        prevPageNumber += 1;
        let prevBlogsData = this.state.blogsData;
        let prevBlogPosts = prevBlogsData.blogPosts;
        prevBlogsData.blogPosts = [...prevBlogPosts, ...blogsData.blogPosts];

        this.setState(prevState => ({
            totalPages: blogsData.totalPages,
            pageNumber: prevPageNumber,
            footerData: prevState.footerData,
            blogsData: prevBlogsData
        }));
    }

    createBlogCards(){
        let cardArray = [];

        this.state.blogsData.blogPosts.forEach( blog => {
            const blogData = {
                date: blog.date,
                year: blog.year,
                month: blog.month,
                day: blog.day
            }

            cardArray.push(
                <Card
                    key={blog.id}
                    id={blog.slug}
                    title={blog.title}
                    description={blog.intro}
                    imageUrl={blog.attachment1}
                    buttonText={blog.buttonText}
                    type={'blog'}
                    blogData={blogData}
                    style={{maxWidth: 'none', marginRight: 0}}
                />
            )
        });

        return cardArray;
    }

    render(){
        const {blogsData, footerData} = this.state;

        if(!footerData || !blogsData){
            return (
                <Loading />
            );
        }

        return(
            <div>
                <MetaTags>
                    <meta property="og:title" content="De Dorus - Blogs"/>
                    <title>De Dorus - Blogs</title>
                    <link rel="canonical" href="https://dedorus.nl/blogs" />
                </MetaTags>

                <Navigation
                    {...this.props}
                    socials={footerData.socials}
                />

                <div className="blog-overview-text">
                    <h1 className="blog-title">{blogsData.detailTitle}</h1>
                    <div className="blog-intro" dangerouslySetInnerHTML={{__html: blogsData.detailDescription}}/>
                </div>

                <div className="blogs-grid-container">
                    <div className="blogs-overview-grid">
                        {this.createBlogCards()}
                    </div>

                    {this.state.pageNumber <= this.state.totalPages &&
                        <div className="blogs-button-container">
                            <button className="show-more-button" onClick={() => this.showMore()}>
                                <span>Show more</span>
                                <img src={ArrowDown} alt="arrow-right"/>
                            </button>
                        </div>
                    }
                </div>

                <Footer data={footerData}/>
            </div>
        );
    }
}

export default withTranslation()(BlogsOverview);