import React from 'react'
import './Card.css'
import Arrow from "../../resources/images/arrow-right.svg";
import {PlaceType} from "../../constants/PlaceType";
import {getI18n, withTranslation} from "react-i18next";

class Card extends React.Component {

    constructor(props){
        super(props)
        this.getRedirectPath = this.getRedirectPath.bind(this);
    }

    getRedirectPath(){
        let redirectPath = "";

        if(getI18n().language === 'en'){
            redirectPath += "/en/";
        } else {
            redirectPath += "/";
        }

        if(this.props.type === PlaceType.OFFICE){
            redirectPath += "offices/" + this.props.id;
        } else if (this.props.type === PlaceType.SPACE){
            redirectPath += "spaces/" + this.props.id;
        } else if (this.props.type === 'blog') {
            redirectPath += "blogs/" + this.props.id;
        }

        return redirectPath;
    }

    render() {

        const {title, description, imageUrl, buttonText, type, priceData, blogData, style} = this.props;
        const trans = this.props.t;

        return(
          <div className="card-container" style={style}>

              <div className="card-image-container">
                  <img className="card-image" src={imageUrl} alt=""/>

                  {priceData
                      ?
                      <div className="card-price-container">
                          <span className="card-price-prefix">{trans("place.pricePrefix")}</span>
                          <span className="card-price">{priceData.price + (priceData.extraText ? priceData.extraText : "")}</span>
                      </div>
                      :
                      null
                  }

                  {type === 'blog'
                      ?
                      <div className="card-price-container">
                          <span className="card-price-prefix">{blogData.year}</span>
                          <span className="card-price">{blogData.month + ' ' + blogData.day}</span>
                      </div>
                      :
                      null
                  }

              </div>

              <h3 className="card-title">{title}</h3>
              <div className="card-description" dangerouslySetInnerHTML={{__html: description}}/>

              {(type === PlaceType.SPACE || type === PlaceType.OFFICE || type === 'blog')
              ?
                  <a className="card-button" href={this.getRedirectPath()}>
                      <span>{buttonText}</span>
                      <img src={Arrow} alt="arrow-right"/>
                  </a>
              :
                  null
              }
          </div>
        );
    }
}

export default withTranslation()(Card);